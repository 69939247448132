<template>
  <el-popconfirm
    v-if="$canAndRulesPass(scope.row, $permissions.CARD_ELEMENT.DELETE)"
    confirm-button-text='Да'
    cancel-button-text='нет'
    icon="el-icon-question"
    title="Вы уверены, что хотите удалить элемент?"
    @confirm="deleteElement(scope.row)"
  >
    <el-button
      slot="reference"
      class="table-action-button"
      type="default"
      round
      size="mini"
      icon="far fa-trash-alt"
      :loading="loading"
    >
    </el-button>
  </el-popconfirm>
</template>

<script>

import ClickToEdit from "@/components/ClickToEdit";
import {ElementPropTableColumn} from "@/mixins/elementPropTableColumn";
export default {
  name: 'element-actions-table-column',
  components: {ClickToEdit},
  mixins: [ElementPropTableColumn],

  props: {
    scope: {type: Object, required: true},
  },
  data() {
    return {
      loading: false,
    }
  },
  computed: {},
  methods: {
    deleteElement(element) {
      this.loading = true;
      this.$api.card.deleteElement({
        id: element.id,
      })
        .then((data) => {
          this.$notify.success({
            title: 'Удалено',
            message: 'Элемент успешно удален'
          });
        })
        .finally(() => {
          this.loading = false;
          this.$emit('elements-changed');
        })
    },
  }
}

</script>

<style>

</style>
